import React, { useState, useEffect } from "react";
import {Container, TextField, Button, Box, Typography, FormControl, InputLabel, Select, MenuItem, Collapse, IconButton, Grid, Stack, Dialog,
  DialogTitle,
  DialogContent,
  DialogActions} from "@mui/material";

import { saveAs } from "file-saver";
import { ExpandLess, ExpandMore } from "@mui/icons-material";


const QuestionForm = ({ index, question, onChange, onRemove }) => {

  const handleChange = (field) => (e) => {
      onChange(index, { ...question, [field]: e.target.value });
    };

  return (
    <Box mt={2} sx={{border:"red"}}>
      <Typography variant="h6">Question {index + 1}</Typography>
      <TextField fullWidth label="Description" value={question.description} onChange={handleChange('description')}/>
      <TextField fullWidth type="number" label="Points" value={question.pts} onChange={handleChange('pts')}/>
      <FormControl fullWidth>
        <InputLabel>Optional</InputLabel>
        <Select value={question.optional} onChange={handleChange('optional')}>
          <MenuItem value={0}>No</MenuItem>
          <MenuItem value={1}>Yes</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>Winner</InputLabel>
        <Select value={question.winner} onChange={handleChange('winner')}>
          <MenuItem value={0}>No</MenuItem>
          <MenuItem value={1}>Yes</MenuItem>
        </Select>
      </FormControl>
      <TextField fullWidth label="Reasoning" value={question.reasoning} onChange={handleChange('reasoning')}/>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button variant="outlined" color="error" onClick={onRemove}>Remove Question</Button>
        </Grid>
      </Grid>
    </Box>
  );
};



const StageForm = ({ stage, onChange, path, handleRemoveStage, recursionLevel = 0, initialStageData }) => {
  const [title, setTitle] = useState(stage.description || "");
  const [timer, setTimer] = useState(stage.quiz.timer || 0);
  const [minPoints, setMinPoints] = useState(stage.quiz.min_points || 0);
  const [questions, setQuestions] = useState(
    stage.quiz.questions
      ? Object.values(stage.quiz.questions)
      : [{ description: "", pts: 0, optional: 0, winner: 0, reasoning: "" }]
  );
  const [nextStage, setNextStage] = useState(stage.yes || "");
  const [prevStage, setPrevStage] = useState(stage.no || "");
  const [collapsed, setCollapsed] = useState(false);
  const stageWidth = 500;

  const handleQuestionChange = (index, question) => {
    const newQuestions = [...questions];
    newQuestions[index] = question;
    setQuestions(newQuestions);
  };

const handleAddStage = (stageKey) => {
  onChange(path, {
    ...stage,
    description: title,
    quiz: {
      timer,
      min_points: minPoints,
      questions: questions.reduce((acc, question, index) => {
        acc[`q${index + 1}`] = question;
        return acc;
      }, {}),
      yes: nextStage,
      no: prevStage,
    },
    [stageKey]: {
      description: "",
      quiz: {
        timer: 0,
        min_points: 0,
        questions: {},
      },
    },
  });
};

  const handleCollapseToggle = () => {
    setCollapsed((prevState) => !prevState);
  };

  const handleRemoveQuestion = (questionIndex) => {
    const newQuestions = questions.filter((_, index) => index !== questionIndex);
    setQuestions(newQuestions);
  };

  useEffect(() => {
    if (initialStageData) {
      setTitle(initialStageData.description || "");
      setTimer(initialStageData.quiz.timer || 0);
      setMinPoints(initialStageData.quiz.min_points || 0);
      setQuestions(
        initialStageData.quiz.questions
          ? Object.values(initialStageData.quiz.questions)
          : [{ description: "", pts: 0, optional: 0, winner: 0, reasoning: "" }]
      );
      setNextStage(initialStageData.yes || "");
      setPrevStage(initialStageData.no || "");
    }
  }, [initialStageData]);

useEffect(() => {
  onChange(path, {
    ...stage,
    description: title,
    quiz: {
      timer,
      min_points: minPoints,
      questions: questions.reduce((acc, question, index) => {
        acc[`q${index + 1}`] = question;
        return acc;
      }, {}),
      yes: nextStage,
      no: prevStage,
    },
  });
}, [title, timer, minPoints, questions, nextStage, prevStage, onChange, path, stage]);

return (
  <Box display="flex" minWidth={stageWidth} width="100%" sx={{flexDirection: "column", alignItems: "center"}}>
    <Typography variant="h5">{`Recursion Level: ${recursionLevel}`}</Typography>
    <Box display="flex" width={stageWidth} justifyContent="space-between">
      <TextField fullWidth label="Title" value={title} onChange={(e) => setTitle(e.target.value)}/>
      <IconButton onClick={handleCollapseToggle}>
        {collapsed ? <ExpandMore /> : <ExpandLess />}
      </IconButton>
    </Box>
    <Collapse in={!collapsed}>
      <TextField  width={stageWidth} type="number" label="Timer" value={timer} onChange={(e) => setTimer(e.target.value)}/>
      <TextField  width={stageWidth} type="number" label="Minimum Points" value={minPoints} onChange={(e) => setMinPoints(e.target.value)}/>
      {questions.map((question, index) => (
        <QuestionForm key={index} index={index} question={question} onChange={handleQuestionChange} onRemove={() => handleRemoveQuestion(index)}/>
      ))}
      <Box mt={2}>
        <Button
          color="primary"
          variant="contained"
          onClick={() =>
            setQuestions([
              ...questions,
              {
                description: "",
                pts: 0,
                optional: 0,
                winner: 0,
                reasoning: "",
              },
            ])
          }
        >
          Add Question
        </Button>
      </Box>
      <Box mt={2}>
        <Button color="primary" variant="contained" onClick={() => handleAddStage("stage2A")}>Add Stage (Yes)</Button>
        <Button color="secondary" variant="contained" onClick={() => handleAddStage("stage2B")}>Add Stage (No)</Button>
                  <Box mt={2}>
<Button
          variant="contained"
          color="error"
          onClick={() => handleRemoveStage(path)}
          disabled={recursionLevel === 0}
        >
          Remove Current Stage
        </Button>
          </Box>
      </Box>
    </Collapse>
    <Box sx={{overflowX: "auto",display: "flex",flexDirection: "row", width: "100%", borderColor:"red", borderStyle:"solid"}}>
      <Stack direction="row" width="100%">
      {stage.stage2A && (
        <Box mt={2} width={stageWidth} marginRight={2} flexShrink={0}>
          <Typography variant="h6">Stage (Yes)</Typography>
          <StageForm
            stage={stage.stage2A}
            onChange={onChange}
            path={`${path}.stage2A`}
             handleRemoveStage={handleRemoveStage}
            recursionLevel={recursionLevel + 1}
          />
        </Box>
      )}
      {stage.stage2B && (
        <Box mt={2} width={stageWidth} marginRight={2} flexShrink={0}>
          <Typography variant="h6">Stage (No)</Typography>
          <StageForm
            stage={stage.stage2B}
            onChange={onChange}
            path={`${path}.stage2B`}
             handleRemoveStage={handleRemoveStage}
            recursionLevel={recursionLevel + 1}
          />
        </Box>
      )}
      </Stack></Box></Box>
);


};

const App = () => {
  const [jsonData, setJsonData] = useState({
    stage1: {
      description: "",
      quiz: {
        timer: 0,
        min_points: 0,
        questions: {},
      },
    },
  });
  const [showJsonDataDialog, setShowJsonDataDialog] = useState(false);
  const [jsonDataAsLines, setJsonDataAsLines] = useState([]);
  const [initialStageData, setInitialStageData] = useState(null);

  const handleJsonDataDialogOpen = () => {
    setShowJsonDataDialog(true);
  };

  const handleJsonDataDialogClose = () => {
    setShowJsonDataDialog(false);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target.result;
        try {
          const data = JSON.parse(contents);
          setJsonData(data);
          setInitialStageData(data.stage1);
        } catch (err) {
          console.error("Invalid JSON file", err);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleSaveFile = () => {
    const data = new Blob([JSON.stringify(jsonData, null, 2)], { type: 'application/json' });
    saveAs(data, 'quiz-data.json');
  };

  const jsonDataAsLines2 = JSON.stringify(jsonData, null, 2).split("\n");

  const generateJsonDataLines = (data, level = 1) => {
    console.log(data)
  const lines = [];
  for (const key in data) {
    const stage = data[key];
    lines.push(`Stage ${level}: ${stage.description}`);
    if (stage.quiz && stage.quiz.questions) {
      lines.push(`  Questions:`);
      Object.values(stage.quiz.questions).forEach((question, index) => {
        lines.push(`    ${index + 1}. ${question.description}`);
      });
    }
    if (stage.stage2A || stage.stage2B) {
      lines.push("");
      lines.push(...generateJsonDataLines(stage, level + 1));
    }
  }
  return lines;
};

 const handleStageChange = (path, stage) => {
  setJsonData((prevData) => {
    const newData = { ...prevData };
    path.split(".").reduce((acc, key, index, arr) => {
      if (index === arr.length - 1) {
        acc[key] = stage;
      } else {
        acc[key] = acc[key] || {};
      }
      return acc[key];
    }, newData);
    return newData;
  });
};

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(jsonData);
  };
  const handleRemoveStage = (path) => {
    const newJsonData = JSON.parse(JSON.stringify(jsonData));

    const pathParts = path.split(".");
    let target = newJsonData;
    for (let i = 0; i < pathParts.length - 1; i++) {
      if (!target[pathParts[i]]) {
        return; // Exit the function if the nested stage doesn't exist
      }
      target = target[pathParts[i]];
    }
    delete target[pathParts[pathParts.length - 1]];

    setJsonData(newJsonData);
  };



  useEffect(() => {
      setJsonDataAsLines(generateJsonDataLines(jsonData));
    }, []);

  return (
  <Container maxWidth={false} disableGutters fullWidth>
    <Box sx={{padding: "2rem",display: "flex",flexDirection: "column", alignItems: "center",width: "100%",overflowX: "auto",}}>
      <Box marginRight={2} flexShrink={0} sx={{display: "flex",flexDirection: "column",alignItems: "center",width: "100%",overflowX: "auto",}}>
        <form onSubmit={handleSubmit}>
           <StageForm
      stage={jsonData.stage1}
      onChange={handleStageChange}
      path="stage1"
      handleRemoveStage={handleRemoveStage}
      initialStageData={initialStageData}
    />
          <Box mt={2}>
            <Button color="primary" variant="contained" type="submit">Submit</Button>
            <Button color="secondary" variant="contained" onClick={handleSaveFile} style={{ marginLeft: 8 }}>Save as File</Button>
          </Box></form>
      </Box>
      <Box mt={2}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleJsonDataDialogOpen}
            >
              Show JSON Data
            </Button>
          </Box>
      <Box mt={2}>
          <input
            accept="application/json"
            style={{ display: "none" }}
            id="file-upload"
            type="file"
            onChange={handleFileUpload}
          />
          <label htmlFor="file-upload">
            <Button variant="contained" color="primary" component="span">
              Load JSON File
            </Button>
          </label>
        </Box>
    </Box>
    <Dialog
        open={showJsonDataDialog}
        onClose={handleJsonDataDialogClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>JSON Data</DialogTitle>
        <DialogContent>
          <pre>
            {jsonDataAsLines.map((line, index) => (
              <div key={index}>{line}</div>
            ))}
          </pre>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleJsonDataDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
  </Container>
  );
};
export default App;